.invite-button > button {
  @apply w-full mx-0 mt-4;
}
.scores-table th {
  @apply text-left bg-xl-sky-grey text-d-grey;
  @apply py-3 px-2;
}

.scores-table tr {
  @apply bg-xl-sky-grey odd:bg-white;
}

.scores-table td {
  @apply text-d-grey;
  @apply py-2.5 px-2;
}

.userRow {
  @apply border-brand border-2;
}
