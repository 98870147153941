input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  @apply text-m-grey;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.input-base {
  @apply border-transparent border-[1px] rounded h-full w-full text-[0px] bg-l-blue;
  @apply hover:border-primary-700 active:border-primary-700;
  @apply focus:autofill:shadow-[inset_0_0_0px_1000px_white] focus:autofill:pt-2;
  @apply disabled:bg-xl-sky-grey disabled:border-xl-sky-grey disabled:border-[0px];
  @apply focus:bg-white focus:text-base focus:border-primary-700 focus:outline-none focus-visible:ring-0;
}

.input-base {
  @apply px-3;
}

.input-base-prefix {
  @apply pl-8 pr-3;
}

.input-error {
  @apply !bg-l-red hover:!border-m-red active:!border-m-red focus:!border-m-red;
}

.input-warning {
  @apply !bg-l-yellow hover:!border-d-yellow active:!border-d-yellow focus:!border-d-yellow;
}

.input-label-base {
  @apply absolute px-3 pointer-events-none border-l-2 border-transparent peer-disabled:text-m-grey;
}

.input-label-base {
  @apply left-0;
}

.input-label-prefix {
  @apply left-5;
}

.input-label-full {
  @apply top-[50%] translate-y-[-50%] text-m-grey peer-autofill:top-auto autofill:text-xs peer-autofill:text-xs peer-autofill:pt-1 peer-autofill:translate-y-0 peer-autofill:text-black;
}

.input-label-small {
  @apply !top-auto !translate-y-0 !text-black text-xs pt-1 peer-focus:top-auto focus:text-xs peer-focus:text-xs peer-focus:pt-1 peer-focus:translate-y-0 peer-focus:text-black;
}

.input-icon-base {
  @apply h-fit w-fit absolute top-[50%] translate-y-[-50%] pointer-events-none bg-l-blue;
  @apply peer-focus:text-primary-700 peer-hover:text-primary-700 peer-focus:bg-white;
  @apply peer-disabled:bg-xl-sky-grey peer-disabled:text-m-grey;
}

.input-icon-error {
  @apply bg-l-red peer-focus:!bg-l-red peer-focus:peer-autofill:!bg-white;
}

.input-icon-warning {
  @apply bg-l-yellow peer-focus:!bg-l-yellow peer-focus:peer-autofill:!bg-white;
}
