.styled-button > button {
  @apply font-bold text-[14pt];
}

.default-button > .bg-primary-700 {
  @apply bg-brand hover:bg-primary-600;
}

.default-button > .border-primary-700 {
  @apply border-brand hover:border-primary-600;
}

.default-button > .text-primary-700 {
  @apply text-brand hover:text-white;
}

.purple-button > button {
  background-color: #6054b8;
  border-color: #6054b8;
}

.purple-button > button:hover {
  background-color: #beb4ff;
  border-color: #beb4ff;
}

.orange-button > button {
  background-color: #f56b0f;
  border-color: #f56b0f;
}

.orange-button > button:hover {
  background-color: #f59556;
  border-color: #f59556;
}

.white-button > button {
  background-color: #ffffff;
  border-color: #ffffff;
}

.white-button > button:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.white-button > button:focus {
  outline: none;
}

.rounded-button > button {
  @apply h-12 w-12 rounded-full;
}

.rounded-button-sm > button {
  @apply h-9 w-9 rounded-full;
}

.icon-button-sm {
  @apply h-9 w-9 cursor-pointer;
}

.padding-xl > button {
  @apply px-12;
}
