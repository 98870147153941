.time-picker input[type="number"] {
  -moz-appearance: textfield;
}

.time-picker input::-webkit-outer-spin-button,
.time-picker input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.time-picker-input input {
  @apply text-left pt-4 m-0 rounded;
}

.active-time-picker input {
  @apply border-primary-400 border-b-0 rounded-b-none;
}

.time-picker svg {
  @apply mt-1;
}
