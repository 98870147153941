@import "./UIPalette/Datepicker/datepicker.css";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "source sans pro", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  @apply text-[16px] lg:text-[18px];
}

p.body-2 {
  @apply text-[14px] lg:text-[16px];
}

p.body-3 {
  @apply text-[12px] lg:text-[14px];
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold text-d-grey pb-2;
}

h1 {
  @apply text-[28px] lg:text-[64px];
}

h2 {
  @apply text-[20px] lg:text-[36px];
}

h3 {
  @apply text-[18px] lg:text-[28px];
}

h4 {
  @apply text-[16px] lg:text-[20px];
}

h5 {
  @apply text-[14px] lg:text-[18px] font-light;
}

h4.cardTitle {
  @apply text-[18px] lg:text-[28px];
}

.form-field-validation-error {
  @apply text-d-red text-xs italic ml-3;
}

.header {
  background-color: #41b0c3;
}

.bright-button > button {
  @apply h-12;
}

.bright-checkbox .bg-primary-400 {
  @apply bg-primary-700;
}

.shadow-bottom {
  @apply shadow-[0_4px_2px_-2px_gray];
}

/* 
  Work around for errors highlighting on @brighthr/component-input 
  Component use both border-neutral-300 and border-error-500 so error borders can be hidden if React
  renders classes in different order (which it can do in dev)
*/
.border-error-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 26 26 / var(--tw-border-opacity)) !important;
}

ul {
  list-style-type: disc;
  padding-inline-start: 40px;
}

nav ul {
  padding-inline-start: 0;
}

a {
  text-decoration: underline;
  @apply text-brand-aa;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sr-only {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px;
  width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}
