.burger-menu {
  -webkit-transition-duration: 600ms;
  transition-duration: 600ms;
  box-shadow: 5px 0 10px 3px rgba(0, 0, 0, 0.2);
}

.burger-menu.open-menu {
  transform: translate(0%);
  -webkit-transform: translate(0%);
}

.burger-menu.closed-menu {
  transform: translate(-120%);
  -webkit-transform: translate(-120%);
}
