.splide__pagination {
  position: relative;
}

.splide__pagination__page {
  width: 0.75rem;
  height: 0.75rem;
  background-color: rgb(187 189 192);
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 12px;
}

.splide__pagination__page.is-active {
  background-color: rgb(77 77 77);
}
