.labelled input {
  @apply m-0 pt-3.5 pb-3.5 border-collapse border-2 rounded bg-l-blue border-l-blue;
}

.labelled .has-error input {
  @apply border-y-d-red border-x-d-red border-2;
}

.password-security-circles {
  -webkit-text-security: circle;
}
