.outerTopBanner {
  @apply relative w-[100vw] !ml-[calc(50%_-_50vw)] left-0 mb-6;
}

.innerTopBanner {
  @apply rounded-[0_0_40%_40%/0_0_30%_30%] pb-2 pt-2 md:pb-1 md:pt-0 text-white;
  background: linear-gradient(100.84deg, #107d8d -9.79%, #56cbdc 53.41%, #309fb0 110.9%);
}

.innerTopBanner svg,
.innerTopBanner h2,
.innerTopBanner h5,
a.breadcrumb,
span.breadcrumb {
  @apply !text-white;
}
