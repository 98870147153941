.suggestions {
    @apply z-OVERLAY absolute max-h-60 overflow-y-scroll drop-shadow-md rounded mt-2;
    scrollbar-color: white rgba(187, 189, 192, 1);    
}

.suggestions::-webkit-scrollbar {
    background-color: white;
}

.suggestions::-webkit-scrollbar-thumb {
    background-color: rgba(187, 189, 192, 1);
}

.suggestion {
    @apply p-2 bg-white z-OVERLAY hover:bg-silver;
}